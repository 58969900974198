<template>
    <div class="container">
        <div class="row justify-content-center align-self-center mx-2">
            <div class="col-lg-6 col-md-8">
                <div class="login-card border-gray my-4">
                    <h3 class="login-title mb-3">{{ $t('reset_password') }}</h3>
                    <p class="mb-2"> {{ $t("reset_password_body") }}</p>
                    <form>
                        <div class="form-group mb-3">
                            <label class="form-label">{{ $t('email') }}</label>
                            <input type="email" v-model="v$.form.email.$model" class="form-control" id="email" tabindex="1">
                        </div>
                        <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
                            <div class="error-msg">*{{ error.$message }}</div>
                        </div>
                        <div class="input-errors" v-if="erroremail">
                            <div class="error-msg">*{{ erroremail }}</div>
                        </div>


                        <div class="login-btn my-4 text-center">
                            <button class="mo-btn" style="max-width: unset" type="submit" @click="resetPAsswordAction">
                                {{ $t('send') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data: function () {
        return {
            form: {
                email: '',
                password: ''
            },
            erroremail: '',
            errorpassword: ''
        }
    },
    methods: {
        resetPAsswordAction: function (e) {
            e.preventDefault()
        }
    },
    validations() {
        return {
            form: {
                email: { required, email },
                password: { required, min: minLength(8) }
            }
        }
    }
}
</script>
  
<style scoped></style>
  
  