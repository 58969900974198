<template>
  <div>
    <div class="card-info">
      <div class="text-center">
        <h5>{{ $t('project_status') }}</h5>
        <p><b>{{ $t('statuses.' + project?.status) }}</b></p>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <h5>{{ $t('order_details') }}</h5>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('order_number') }}</p>
        <p>{{ project.id }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('service_type') }}</p>
        <p>{{ project?.service?.title_ar }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('check_data') }}</p>
        <p>{{ $filters.formatDate(checkerInfo?.date) }}</p>

      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('checker_name') }}</p>
        <p>{{ checkerInfo?.name }}</p>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <h5>{{ $t('building_details') }}</h5>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_type') }}</p>
        <p>{{ project.ownership?.title_ar != null ? project.ownership?.title_ar : project.other }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_area') }}</p>
        <p>{{ project.space }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('building_no') }}</p>
        <p>{{ project.building_no }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('unit_no') }}</p>
        <p>{{ project.unit_no }}</p>
      </div>

      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('street_number') }}</p>
        <p>{{ project.unit_no }}</p>
      </div>
      <div class="justify-content-between align-items-center d-flex">
        <p>{{ $t('zone_no') }}</p>
        <p>{{ project.title }}</p>
      </div>
    </div>
    <div class="card-info">
      <div class="justify-content-between align-items-center d-flex">
        <h5>{{ $t('project_document') }}</h5>
      </div>
      <div class="justify-content-between align-items-center d-flex my-2" v-for="item in projectDocs" :key="item.id">
        <p>{{ item.name }}</p>
        <i class="fa fa-check fa-2x" style="color: green" v-if="item.uploaded == 'true'"></i>
        <i class="fa fa-xmark fa-2x" style="color: red" v-else></i>
      </div>
    </div>
    <div class="text-center my-2" v-if="(!project.hierarchy_done || project.hierarchy_done.length == 0)">
      <router-link v-if="project.id" :to="'/checker-project-form/' + project.id" class="mo-btn"
                   style="max-width: unset">
        {{ $t('request_new_form') }}
      </router-link>
    </div>
    <div v-else class="d-flex justify-content-center align-items-center">
      <div @click="projectDetailsShown = true" v-if="project.status != 'EndCheck'" class="mo-btn mx-2"
           style="cursor: pointer">{{ $t('project_details') }}
      </div>
      <div @click="projectDocumentsShown = !projectDocumentsShown" v-if="project.status != 'EndCheck'"
           class="mo-btn mx-2" style="cursor: pointer">{{ $t('project_document') }}
      </div>
      <div @click="projectFormShown = !projectFormShown" class="mo-btn mx-2 project-btn" style="cursor: pointer">
        {{ $t('project_form') }}
      </div>
      <div @click="closeForm" v-if="project.hierarchy_done != null && project.status != 'EndCheck'" class="mo-btn mx-2"
           style="cursor: pointer">{{ $t('close_form') }}
      </div>
      <b-modal v-model="projectDocumentsShown" hide-footer v-if="project.status != 'EndCheck'">
        <div class="card-info" :title="$t('project_document')">
          <div class="row">
            <div class="col-md-12 col-sm-12 mb-2" v-for="(item, key) in projectDocumentsArr" :key="key">
              <div class="form-group">
                <label> {{ item.name }} {{ `- ${item.index}` }}</label>
                <input class="form-control" type="file" @change="appendProjectDocs($event.target.files, item.id)"
                       multiple/>
                <div v-for="doc in projectDocuments[item.id]" :key="doc.id">
                  <div class="d-flex align-items-center">
                    <div class="mx-2 d-flex justify-content-between align-items-center" style="text-overflow: ellipsis">
                      <h4>{{ $t('filename') }}</h4>: {{ doc.item.name.substring(0, 55) + "..." }}
                    </div>
                    <button @click="deleteDoc(doc.src, item.id)" class="delete-btn">{{ $t('delete') }}</button>
                  </div>
                </div>
              </div>
            </div>
            <button class="mo-btn mo-vtn-block" style="width: 100%" @click="sendProjectDocs">{{
                $t('send_docs')
              }}
            </button>
          </div>
        </div>
      </b-modal>
      <b-modal v-model="projectFormShown" hide-footer size="xl">
        <div class="form-area">
          <div class="card-info">
            <div class="row">
              <div class="justify-content-between align-items-center d-flex">
                <h5>{{ $t('project_form') }}</h5>
              </div>
              <div class="col-md-4 col-sm-12 mb-2" v-for="(item, key) in project.hierarchy_done" :key="key">
                <div class="first-level-card" @click="showTreeLevel1(item)">
                  {{ item.name }} {{ `- ${item.index}` }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-info" v-show="treeLevel1IsShown">
            <div class="row">
              <div class="justify-content-between align-items-center d-flex">
                <h5>{{ level1Name }}</h5>
              </div>
              <h4 v-if="!treeLevel1.length && treeLevel1IsShown">{{ $t('no_data') }}</h4>
              <div class="col-md-4 col-sm-12 mb-2" v-for="(item, key) in treeLevel1" :key="key">
                <div class="first-level-card" @click="showTreeLevel2(item)">
                  {{ item?.name }} {{ `- ${item?.index}` }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-info" v-show="treeLevel2IsShown">
            <h5 class="my-2">{{ this.level2NameWithIndex }}</h5>
            <h4 v-if="!treeLevel2.length && treeLevel2IsShown">{{ $t('no_data') }}</h4>
            <div class="row">
              <div class="col-md-4 col-sm-12 mb-2" v-for="(item, key) in treeLevel2" :key="key">
                <tree-view :data="item" :title="title" :project="project" @refresh="refresh"></tree-view>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal v-model="projectDetailsShown" hide-footer size="xl">
        <div class="form-area">
          <div class="card-info">
            <div class="row">
              <div class="justify-content-between align-items-center d-flex">
                <h5>{{ $t('project_image') }}</h5>
              </div>
              <div class="col-md-12 col-sm-12 mb-2">
                <input accept="image/*;capture=camera"
                       @change="appendFiles($event.target.files)"
                       class="form-control" type="file"/>

                <img class="project-image my-4" v-if="project?.image?.length" :src="resolveImageFromPublic(project.image)">
              </div>
            </div>
          </div>
          <div class="card-info">
            <div class="row">
              <div class="justify-content-between align-items-center d-flex">
                <h5>{{ $t('project_details') }}</h5>
              </div>
              <div class="col-md-12 col-sm-12 mb-2">
                <div class="form-group" v-for="(item,index) of Object.keys(projectDetails.details)" :key="index">
                  <label for="building_status">{{ $t(item) }}</label>
                  <input type="text" class="form-control" id="building_status" v-model="projectDetails.details[item]">
                </div>
              </div>
            </div>
          </div>
          <div class="card-info">
            <div class="row">
              <div class="justify-content-between align-items-center d-flex">
                <h5>{{ $t('project_infrastructure') }}</h5>
              </div>
              <div class="col-md-2 col-sm-2 mb-2" v-for="(item,index) of projectDetails.infrastructure" :key="index">
                <div class="form-group">
                  <label for="building_status">{{ item.name }}</label>
                  <input type="checkbox"
                         @change="projectDetails.infrastructure[index].valid = !projectDetails.infrastructure[index].valid"
                         class="form-control checkbox mx-2">
                </div>
              </div>
            </div>
          </div>
          <div class="card-info">
            <div class="row">
              <div class="justify-content-between align-items-center d-flex">
                <h5>{{ $t('project_feature') }}</h5>
              </div>
              <div class="col-md-2 col-sm-2 mb-2" v-for="(item,index) of projectDetails.feature" :key="index">
                <div class="form-group">
                  <label for="building_status">{{ item.name }}</label>
                  <input type="checkbox"
                         @change="projectDetails.feature[index].valid = !projectDetails.feature[index].valid"
                         class="form-control checkbox mx-2">
                </div>
              </div>
            </div>
          </div>
          <div @click="saveProjectDetails" class="mo-btn mx-2 project-btn" style="cursor: pointer">{{
              $t('save')
            }}
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import apis from "@/service/apis";
import TreeView from "@/components/Shared/TreeView";

export default {
  name: "OrderDetails",
  components: {TreeView},
  data() {
    return {
      projectDocumentsShown: false,
      projectFormShown: false,
      projectDetailsShown: false,
      project: {},
      checkerInfo: {},
      projectDocumentsArr: [],
      projectDetails: {
        details: {
          "building_status": '',
          "entry_number": '',
          "contract_number": '',
          "owner": '',
          "artitacture_developer": '',
          "office_design": '',
          "supervisor_eng": '',
          "main_provider": '',
          "electri_counter1": '',
          "electri_counter2": '',
          "electri_counter_reed1": '',
          "electri_counter_reed2": '',
          "water_counter": '',
          "water_counter_reed": '',
          "property_age": '',
          "ground_space": '',
          "building_spaces": '',
          "floors": '',
        }
      },
      projectDocuments: [],
      projectDocs: [],
      treeLevel1: [],
      level1Name: '',
      treeLevel1IsShown: false,
      treeLevel2: [],
      level2Name: '',
      level2NameWithIndex: '',
      treeLevel3: [],
      treeLevel2IsShown: false,
      level3Name: '',
      treeLevel3IsShown: false,
      title: ''
    };
  },
  methods: {
    showTreeLevel1(data) {
      this.title = data.name + '-' + data.index;
      this.level1Name = this.title;
      this.level2NameWithIndex = '';
      this.treeLevel1IsShown = true;
      this.treeLevel1 = data.child;
      this.treeLevel2 = []
    },
    showTreeLevel2(data) {
      this.level2NameWithIndex = data.name + '-' + data.index;
      this.level2Name = this.level2NameWithIndex;
      this.title = this.title + ' > ' + data.name + '-' + data.index
      this.treeLevel2IsShown = true;
      this.treeLevel2 = data.child;
    },

    deleteDoc(src, id) {
      this.projectDocuments[id] = this.projectDocuments[id].filter(item => item.src != src)
    },
    appendFiles(files) {
      this.projectDetails.image = files[0];
    },

    appendProjectDocs(data, id) {
      if (!this.projectDocuments[id] && !this.projectDocuments[id]?.length) {
        this.projectDocuments[id] = [];
      }
      Object.values(data).forEach(item => {
        this.projectDocuments[id].push({
          item: item,
          src: URL.createObjectURL(item)
        });
      })
    },
    sendProjectDocs() {
      this.projectDocuments.forEach((item, id) => {
        const files = {
          document_type_id: id,
          project_id: this.project.id,
          "images[]": item.map((item2) => item2.item)
        };
        this.$store.dispatch('addProjectDocs', files).then(() => {
          if (Object.keys(this.projectDocuments).length == id) {
            this.refresh()
          }
        })
      });
    },
    async saveProjectDetails() {
      if (this.projectDetails.image) {
        await this.$store.dispatch('addImage', {
          project_id: this.project.id,
          image: this.projectDetails.image,
        })
      }
      await this.$store.dispatch('addDetails', {
        project_id: this.project.id,
        ...this.projectDetails.details
      })
      await this.$store.dispatch('addInfrastructure', {
        project_id: this.project.id,
        project_infrastructures: this.projectDetails.infrastructure
      })
      await this.$store.dispatch('addFeature', {
        project_id: this.project.id,
        project_features: this.projectDetails.feature
      })
      this.projectDetailsShown = false
    },
    async refresh(openAgain = false) {
      this.projectDocumentsShown = false;
      this.project
          = this.treeLevel1
          = this.treeLevel2
          = this.treeLevel3
          = this.projectDocumentsArr
          = this.projectDocuments
          = this.projectDocs = [];

      await this.axios.get(apis.projects + '/' + this.$route.params.id)
          .then((response) => {
            this.project = response.data?.data.project;
            this.projectDocs = response.data?.data.documents;
            this.checkerInfo = response.data?.data.checkerinfo;
            this.projectDetails.details.building_status =  response.data?.data.project.building_status;
            this.projectDetails.details.entry_number =  response.data?.data.project.entry_number;
            this.projectDetails.details.owner =  response.data?.data.project.owner;
            this.projectDetails.details.artitacture_developer =  response.data?.data.project.artitacture_developer;
            this.projectDetails.details.supervisor_eng =  response.data?.data.project.contract_number;
            this.projectDetails.details.office_design =  response.data?.data.project.office_design;
            this.projectDetails.details.main_provider =  response.data?.data.project.main_provider;
            this.projectDetails.details.building_status =  response.data?.data.project.building_status;
            this.projectDetails.details.electri_counter1 =  response.data?.data.project.electri_counter1;
            this.projectDetails.details.electri_counter2 =  response.data?.data.project.electri_counter2;
            this.projectDetails.details.electri_counter_reed1 =  response.data?.data.project.electri_counter_reed1;
            this.projectDetails.details.electri_counter_reed2 =  response.data?.data.project.electri_counter_reed2;
            this.projectDetails.details.water_counter =  response.data?.data.project.water_counter;
            this.projectDetails.details.water_counter_reed =  response.data?.data.project.water_counter_reed;
            this.projectDetails.details.property_age =  response.data?.data.project.property_age;
            this.projectDetails.details.ground_space =  response.data?.data.project.ground_space;
            this.projectDetails.details.floors =  response.data?.data.project.floors;
            this.projectDetails.details.building_spaces =  response.data?.data.project.building_spaces;

          })
      await this.axios.get(apis.documentType)
          .then((response) => {
            this.projectDocumentsArr = response.data?.data
            if (openAgain) {
              document.querySelector('.project-btn').click();
              if (this.level1Name) {
                this.showTreeLevel1(this.project.hierarchy_done.find(item => item.name == this.level1Name))
              }
              if (this.level2Name) {
                this.showTreeLevel2(this.treeLevel1.find(item => item.name == this.level2Name))
              }
            }
          })
      document.querySelector('body').style.overflowY = 'scroll'
    },
    closeForm() {
      this.$store.dispatch('closeForm', this.$route.params.id).then(() => {
        this.$router.push({
          name: 'checkerDashboard',
        })
      });
    }
  },
  async created() {
    await this.refresh()
    this.$store.dispatch('getInfrastructure').then(res => {
      this.projectDetails.infrastructure = res;
    })
    this.$store.dispatch('getFeature').then(res => {
      this.projectDetails.feature = res;
    })
  },
};
</script>

<style>
.map {
  height: 300px;
  border-radius: 30px;
}

.card-info {
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  margin: 20px 0;
}

.icon-area {
  width: 44px;
  height: 42px;
  background-color: #c7c6c6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;

}
.project-image{
  width: 200px;
  height: 200px;
}
.icon-area img {
  width: 20px;
  height: 20px;
}

button {
  max-width: 75px;
  padding: 14px;
  border: 0;
}

.first-level-card {
  width: 100%;
  height: 70px;
  background-color: var(--main-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
}

.delete-btn {
  padding: 6px;
  color: white;
  background-color: red;
  border-radius: 12px;
  margin: 10px -0;
}
</style>
